<template>
  <v-form ref="formElem">
    <v-card>
      <v-card-text>
        <p>
          Adding alternate setting for distributor
          from <b>{{ resource.key }}</b>.
        </p>

        <v-select
          v-model="form.distributor_id"
          :items="distributorOptions"
          item-text="title"
          item-value="value"
          label="Select Distributor"
          outlined
          hide-details
          class="mt-5"
        ></v-select>

        <v-text-field
          v-model="form.value"
          label="Value"
          outlined
          class="mt-5"
        />
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { useDistributor } from '@/composables'
import { between, required } from '@core/utils/validation'
import { } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const field = (name, initValue) => ({ [name]: props.resource ? props.resource[name] : initValue })

    const initialForm = {
      // ...field('category', ''),
      parent_id: props.resource.id,
      distributor_id: null,
      ...field('value', ''),
    }

    const { distributorOptions } = useDistributor()

    const form = ref({ ...initialForm })
    const formElem = ref(null)

    const validate = () => {
      if (!formElem.value.validate()) return
      const data = { ...form.value }
      emit('submit', data)
    }

    return {
      distributorOptions,
      form,
      formElem,
      validate,
      required,
      between,
    }
  },
}
</script>
